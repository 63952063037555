<template>
  <div class="content-wrapper">
    <div class="d-flex justify-content-between">
      <h1 class="mb-5">{{ idOrdine }}</h1>
      <div style="font-size: 1.5rem">
        <b-badge variant="warning">
          <b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
        </b-badge>
      </div>
    </div>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <b-button
            size="sm"
            class="mb-2"
            v-b-toggle.collapse-advancedFilter
            :disabled="advancedFilter_visible"
          >
            <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
          </b-button>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-partners>
            <b-icon icon="plus" aria-hidden="true"></b-icon> Genera documento di
            Dossier
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-collapse
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-form-select
              value="All Dossier Manager"
              :options="['All Dossier Manager']"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select value="All CE" :options="['All CE']"></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              value="All Dossier"
              :options="['All Dossier']"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              value="All Costumer"
              :options="['All Costumer']"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-overlay
      :show="items == null || items.length <= 0"
      rounded="sm"
      class="overlay-placeholder"
    >
      <b-card v-for="item of items" :key="item.id" class="mb-4">
        <div class="header d-flex justify-content-between mb-4">
          <div class="d-flex" style="flex-direction: column">
            <div>
              <span style="font-weight:bold; font-size: 1.2rem;">{{
                item.luogoInizio
              }}</span>
              -
              <span
                >{{ new Date(item.dataOraInizio).toLocaleDateString("it-IT") }}
                -
                {{
                  new Date(item.dataOraInizio).toLocaleTimeString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}</span
              >
            </div>
            <div>
              <span
                v-if="item.luogoFine"
                style="font-weight:bold; font-size: 1.2rem;"
                >{{ item.luogoFine }}</span
              >
              -
              <span v-if="item.dataOraFine"
                >{{ new Date(item.dataOraFine).toLocaleDateString("it-IT") }} -
                {{
                  new Date(item.dataOraFine).toLocaleTimeString("it-IT", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}</span
              >
            </div>
          </div>
          <div>
            <div v-if="item.stato == '2'" @click="item.stato = 'Esclusa'">
              <span class="stato" style="color: green; font-weight: bold;">
                <b-icon-circle-fill></b-icon-circle-fill> Pubblicazione
              </span>
            </div>
            <div v-if="item.stato == '1'" @click="item.stato = 'Esclusa'">
              <span class="stato" style="color: orange; font-weight: bold;">
                <b-icon-circle-fill></b-icon-circle-fill> Interna
              </span>
            </div>
            <div v-if="item.stato == '0'" @click="item.stato = 'Pubblica'">
              <span class="stato" style="color: maroon; font-weight: bold;">
                <b-icon-circle-fill></b-icon-circle-fill> Esclusa
              </span>
            </div>
          </div>
        </div>
        <div class="body mb-4">
          <div class="images">
            <div
              @click="setCurrentImg(img)"
              v-b-modal.modal-img
              class="image"
              v-for="(img, index) of item.elementiMultimediali"
              :key="index"
            >
              <img :class="setClassStato(img.stato)" :src="img.urlElemento" />
            </div>
          </div>
          <div
            v-if="item.descrizioneHistory[0]"
            class="rilevazione-content mt-4"
          >
            <span v-if="!item.editMode" style="font-size:1.2rem">{{
              item.descrizioneHistory[0].testo
            }}</span>
          </div>
          <b-form-group v-if="item.editMode">
            <b-form-textarea
              v-model="nuovaDescrizione"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <div v-if="descrizioneHistoryVisible">
            <div
              class="my-4"
              style="color: gray"
              v-for="(log, i) in item.descrizioneHistory"
              :key="i"
            >
              {{ log.testo }}

              <div style="font-size: .8rem; font-style:italic">
                <span style="font-weight: bold">{{ log.Utente }}:</span>
                {{ new Date(log.dataOraInserimento).toLocaleDateString("it") }}
                -
                {{
                  new Date(log.dataOraInserimento).toLocaleTimeString("it", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="footer d-flex justify-content-between">
          <div class="agente">
            <b-avatar
              v-if="item.CieFoto"
              :src="item.CieFoto"
              class="mr-2"
            ></b-avatar>
            <b-avatar v-else class="mr-2"></b-avatar>
            <span style="font-weight:bold">{{ item.CieRegistrato }}</span>
          </div>
          <div class="azioni">
            <b-icon-pencil-fill
              v-if="!item.editMode"
              @click="item.editMode = true"
              role="button"
              class="mr-2"
            >
            </b-icon-pencil-fill>
            <div v-else>
              <b-button @click="salvaDescrizione(item)" class="mb-4"
                >Salva</b-button
              >
            </div>
            <b-icon-calendar-fill
              v-if="!descrizioneHistoryVisible"
              @click="showDescrizioneHistory"
              role="button"
              class="mr-2"
            ></b-icon-calendar-fill>
            <b-icon-calendar
              v-else
              @click="hideDescrizioneHistory"
              role="button"
              class="mr-2"
            ></b-icon-calendar>
            <b-badge role="button" variant="primary">Download Immagini</b-badge>
          </div>
        </div>
      </b-card>
    </b-overlay>

    <b-sidebar
      class="right-sidebar"
      id="sidebar-ordine-di-servizio"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="tmpItem && tmpItem.title">
          {{ tmpItem.title }}
        </h4>
        <h4 v-else>New dossier</h4>
        <b-row>
          <b-col>
            <b-form-group label="Agente assegnato">
              <b-form-input v-model="tmpItem.agente" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Luogo inizio Servizio">
              <b-form-input v-model="tmpItem.luogo_inizio" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Data ora suggerita">
              <b-row>
                <b-col>
                  <b-form-datepicker
                    v-model="tmpItem.data_suggerita"
                    :min="new Date()"
                    locale="it"
                  ></b-form-datepicker>
                </b-col>
                <b-col>
                  <b-form-timepicker
                    v-model="tmpItem.ora_suggerita"
                    locale="it"
                  ></b-form-timepicker>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Lista sospettati da seguire">
              <b-form-textarea
                v-model="tmpItem.sospettati"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Annotazioni Dossier Manager">
              <b-form-textarea
                v-model="tmpItem.annotazioni"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-5 mb-5">
          <b-button
            v-if="tmpItem.confermato"
            disabled
            squared
            variant="outline-primary"
            size="lg"
            class="big-button"
          >
            <b-icon-check></b-icon-check> Confermato
          </b-button>
          <b-button
            v-else
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
          >
            Conferma</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal id="modal-img" size="xl" centered v-model="show" hide-footer>
      <img class="modal-img" :src="currentImg.urlElemento" />

      <div class="d-flex justify-content-end mt-4">
        <span
          v-if="currentImg.stato == 2"
          @click="currentImg.stato = 0"
          class="stato"
          style="color: green; font-weight: bold;"
        >
          <b-icon-circle-fill></b-icon-circle-fill> Pubblicazione
        </span>

        <span
          v-if="currentImg.stato == 0"
          @click="currentImg.stato = 1"
          class="stato"
          style="color: maroon; font-weight: bold;"
        >
          <b-icon-circle-fill></b-icon-circle-fill> Esclusa
        </span>

        <span
          v-if="currentImg.stato == 1"
          @click="currentImg.stato = 2"
          class="stato"
          style="color: orange; font-weight: bold;"
        >
          <b-icon-circle-fill></b-icon-circle-fill> Interna
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService"
export default {
  created() {
    this.idOrdine = this.$route.params.idOrdine

    this.getRilevazioni()
  },
  data() {
    return {
      advancedFilter_visible: false,
      show: false,
      currentImg: "",
      filter: "",
      idOrdine: null,
      currentItem: null,
      tmpItem: {
        id: null,
        title: "",
        luogo_inizio: "",
        confermato: false,
        agente: "",
        data_suggerita: "",
        ora_suggerita: "",
        sospettati: null,
        annotazioni: "",
      },

      items: [],
      descrizioneHistoryVisible: false,
      nuovaDescrizione: "",
    }
  },
  methods: {
    resetData() {
      this.tmpItem = {
        id: null,
        title: "",
        luogo_inizio: "",
        confermato: false,
        agente: "",
      }
    },
    saveItem(event) {
      console.log(event)
    },
    setCurrentImg(img) {
      this.currentImg = {}
      this.currentImg = img
    },
    getRilevazioni() {
      console.log("this ordine di servizio ", this.idOrdine)

      const service = new AxiosService(
        "DossierManager/GetRilevazioni/" + this.idOrdine
      )
      service.read().then((res) => {
        res.map((x) => (x.editMode = false))
        this.items = res
        console.log(res)
      })
    },
    setClassStato(id) {
      let imgClass = ""
      switch (id) {
        case 0:
          imgClass = "Esclusa"
          break
        case 1:
          imgClass = "Interna"
          break
        case 2:
          imgClass = "Pubblicazione"
          break
      }
      return imgClass
    },
    showDescrizioneHistory() {
      this.descrizioneHistoryVisible = true
    },
    hideDescrizioneHistory() {
      this.descrizioneHistoryVisible = false
    },
    salvaDescrizione(item) {
      const service = new AxiosService(
        "DossierManager/EditDescrizioneRilevazione/" + item.id
      )
      const nuovaDescrizioneObj = {
        descrizione: this.nuovaDescrizione,
        elementiMultimediali: [],
      }

      service
        .create(nuovaDescrizioneObj)
        .then((res) => console.log(res))
        .finally(() => {
          this.descrizioneHistoryVisible = false
          item.editMode = false
        })
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0
    },

    // ...mapGetters(["languages", "default_lang", "config"]),
    //     ...mapGetters(["default_lang"]),
    //     ...mapGetters(["config"]),
  },
}
</script>

<style>
.stato:hover {
  cursor: pointer;
}

.images {
  padding: 1rem 0;
}

img {
  border-radius: 5px;
}

.Pubblicazione {
  border: 4px solid green;
}

.Interna {
  border: 4px solid orange;
}

.Esclusa {
  border: 4px solid maroon;
  opacity: 0.5;
}
</style>
